@font-face {
  font-family: "icuraya";
  font-style: normal;
  font-weight: normal;
  src:  url("fonts/icuraya.eot?mpc3z2");
  src:  url("fonts/icuraya.eot?mpc3z2#iefix") format("embedded-opentype"),
    url("fonts/icuraya.ttf?mpc3z2") format("truetype"),
    url("fonts/icuraya.woff?mpc3z2") format("woff"),
    url("fonts/icuraya.svg?mpc3z2#icuraya") format("svg");
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icuraya" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-online:before {
  content: "\ed08";
}

.icon-user-1:before {
  content: "\ecf3";
}

.icon-user-1-clock-bottom:before {
  content: "\ecf4";
}

.icon-user-1-clock-bottom-right:before {
  content: "\ecf5";
}

.icon-user-1-clock-face:before {
  content: "\ecf6";
}

.icon-user-1-clock-top-right:before {
  content: "\ecf7";
}

.icon-user-1-clock-top-right-big:before {
  content: "\ecf8";
}

.icon-user-2:before {
  content: "\ecf9";
}

.icon-user-2-condensed:before {
  content: "\ecfa";
}

.icon-user-3-clock-bottom:before {
  content: "\ecfb";
}

.icon-user-3-clock-top:before {
  content: "\ecfc";
}

.icon-user-3-clock-top-big:before {
  content: "\ecfd";
}

.icon-user-3-condensed:before {
  content: "\ecfe";
}

.icon-user-6:before {
  content: "\ecff";
}

.icon-user-6-clock-bottom:before {
  content: "\ed00";
}

.icon-user-6-clock-top:before {
  content: "\ed01";
}

.icon-user-2-clock-bottom:before {
  content: "\ed02";
}

.icon-user-2-clock-bottom-right:before {
  content: "\ed03";
}

.icon-user-2-clock-top:before {
  content: "\ed04";
}

.icon-user-2-clock-top-right:before {
  content: "\ed05";
}

.icon-user-2-clock-top-right-big:before {
  content: "\ed06";
}

.icon-user-2-top-big:before {
  content: "\ed07";
}

.icon-plus-circle-fill:before {
  content: "\e900";
}

.icon-plus-fill-c:before {
  content: "\e901";
}

.icon-plus-medi-fill:before {
  content: "\e902";
}

.icon-plus-medi:before {
  content: "\e903";
}

.icon-plus-round-fill:before {
  content: "\e904";
}

.icon-plus-round:before {
  content: "\e905";
}

.icon-plus-sm:before {
  content: "\e906";
}

.icon-policy-fill:before {
  content: "\e907";
}

.icon-policy:before {
  content: "\e908";
}

.icon-power:before {
  content: "\e909";
}

.icon-printer-fill:before {
  content: "\e90a";
}

.icon-printer:before {
  content: "\e90b";
}

.icon-propert-blank:before {
  content: "\e90c";
}

.icon-property-add:before {
  content: "\e90d";
}

.icon-property-alt:before {
  content: "\e90e";
}

.icon-property-remove:before {
  content: "\e90f";
}

.icon-property:before {
  content: "\e910";
}

.icon-puzzle-fill:before {
  content: "\e911";
}

.icon-puzzle:before {
  content: "\e912";
}

.icon-python:before {
  content: "\e913";
}

.icon-qr:before {
  content: "\e914";
}

.icon-question-alt:before {
  content: "\e915";
}

.icon-question:before {
  content: "\e916";
}

.icon-quote-left:before {
  content: "\e917";
}

.icon-quote-right:before {
  content: "\e918";
}

.icon-react:before {
  content: "\e919";
}

.icon-reddit-circle:before {
  content: "\e91a";
}

.icon-reddit-round:before {
  content: "\e91b";
}

.icon-reddit:before {
  content: "\e91c";
}

.icon-redo:before {
  content: "\e91d";
}

.icon-regen-alt:before {
  content: "\e91e";
}

.icon-regen:before {
  content: "\e91f";
}

.icon-reload-alt:before {
  content: "\e920";
}

.icon-reload:before {
  content: "\e921";
}

.icon-repeat-fill:before {
  content: "\e922";
}

.icon-repeat-v:before {
  content: "\e923";
}

.icon-repeat:before {
  content: "\e924";
}

.icon-reply-all-fill:before {
  content: "\e925";
}

.icon-reply-all:before {
  content: "\e926";
}

.icon-reply-fill:before {
  content: "\e927";
}

.icon-reply:before {
  content: "\e928";
}

.icon-report-fill:before {
  content: "\e929";
}

.icon-report-profit:before {
  content: "\e92a";
}

.icon-report:before {
  content: "\e92b";
}

.icon-reports-alt:before {
  content: "\e92c";
}

.icon-reports:before {
  content: "\e92d";
}

.icon-rewind-fill:before {
  content: "\e92e";
}

.icon-rewind:before {
  content: "\e92f";
}

.icon-row-mix:before {
  content: "\e930";
}

.icon-row-view:before {
  content: "\e931";
}

.icon-row-view1:before {
  content: "\e932";
}

.icon-rss:before {
  content: "\e933";
}

.icon-sad-fill:before {
  content: "\e934";
}

.icon-sad:before {
  content: "\e935";
}

.icon-save-fill:before {
  content: "\e936";
}

.icon-save:before {
  content: "\e937";
}

.icon-scan-fill:before {
  content: "\e938";
}

.icon-scan:before {
  content: "\e939";
}

.icon-scissor:before {
  content: "\e93a";
}

.icon-search:before {
  content: "\e93b";
}

.icon-security:before {
  content: "\e93c";
}

.icon-send-alt:before {
  content: "\e93d";
}

.icon-send:before {
  content: "\e93e";
}

.icon-server-fill:before {
  content: "\e93f";
}

.icon-server:before {
  content: "\e940";
}

.icon-setting-alt-fill:before {
  content: "\e941";
}

.icon-setting-alt:before {
  content: "\e942";
}

.icon-setting-fill:before {
  content: "\e943";
}

.icon-setting:before {
  content: "\e944";
}

.icon-share-alt:before {
  content: "\e945";
}

.icon-share-fill:before {
  content: "\e946";
}

.icon-share:before {
  content: "\e947";
}

.icon-shield-alert-fill:before {
  content: "\e948";
}

.icon-shield-alert:before {
  content: "\e949";
}

.icon-shield-check-fill:before {
  content: "\e94a";
}

.icon-shield-check:before {
  content: "\e94b";
}

.icon-shield-fill:before {
  content: "\e94c";
}

.icon-shield-half:before {
  content: "\e94d";
}

.icon-shield-off:before {
  content: "\e94e";
}

.icon-shield-star-fill:before {
  content: "\e94f";
}

.icon-shield-star:before {
  content: "\e950";
}

.icon-shield:before {
  content: "\e951";
}

.icon-shrink:before {
  content: "\e952";
}

.icon-shuffle:before {
  content: "\e953";
}

.icon-sidebar-fill:before {
  content: "\e954";
}

.icon-sidebar-r:before {
  content: "\e955";
}

.icon-sidebar:before {
  content: "\e956";
}

.icon-sign-ada-alt:before {
  content: "\e957";
}

.icon-sign-ada:before {
  content: "\e958";
}

.icon-sign-bch-alt:before {
  content: "\e959";
}

.icon-sign-bch:before {
  content: "\e95a";
}

.icon-sign-bgp-alt:before {
  content: "\e95b";
}

.icon-sign-bnb-alt:before {
  content: "\e95c";
}

.icon-sign-bnb:before {
  content: "\e95d";
}

.icon-sign-brl-alt:before {
  content: "\e95e";
}

.icon-sign-brl:before {
  content: "\e95f";
}

.icon-sign-btc-alt:before {
  content: "\e960";
}

.icon-sign-btc:before {
  content: "\e961";
}

.icon-sign-cc-alt:before {
  content: "\e962";
}

.icon-sign-cc-alt2:before {
  content: "\e963";
}

.icon-sign-chf-alt:before {
  content: "\e964";
}

.icon-sign-chf:before {
  content: "\e965";
}

.icon-sign-cny-alt:before {
  content: "\e966";
}

.icon-sign-cny:before {
  content: "\e967";
}

.icon-sign-czk-alt:before {
  content: "\e968";
}

.icon-sign-czk:before {
  content: "\e969";
}

.icon-sign-dash-alt:before {
  content: "\e96a";
}

.icon-sign-dash:before {
  content: "\e96b";
}

.icon-sign-dkk-alt:before {
  content: "\e96c";
}

.icon-sign-eos-alt:before {
  content: "\e96d";
}

.icon-sign-eos:before {
  content: "\e96e";
}

.icon-sign-eth-alt:before {
  content: "\e96f";
}

.icon-sign-eth:before {
  content: "\e970";
}

.icon-sign-eur-alt2:before {
  content: "\e971";
}

.icon-sign-euro-alt:before {
  content: "\e972";
}

.icon-sign-euro:before {
  content: "\e973";
}

.icon-sign-gbp-alt2:before {
  content: "\e974";
}

.icon-sign-gbp:before {
  content: "\e975";
}

.icon-sign-hkd-alt:before {
  content: "\e976";
}

.icon-sign-hkd:before {
  content: "\e977";
}

.icon-sign-idr-alt:before {
  content: "\e978";
}

.icon-sign-idr:before {
  content: "\e979";
}

.icon-sign-inr-alt:before {
  content: "\e97a";
}

.icon-sign-inr:before {
  content: "\e97b";
}

.icon-sign-jpy-alt:before {
  content: "\e97c";
}

.icon-sign-jpy:before {
  content: "\e97d";
}

.icon-sign-kr-alt:before {
  content: "\e97e";
}

.icon-sign-ltc-alt:before {
  content: "\e97f";
}

.icon-sign-ltc:before {
  content: "\e980";
}

.icon-sign-mxn-alt:before {
  content: "\e981";
}

.icon-sign-mxn:before {
  content: "\e982";
}

.icon-sign-mxr-alt:before {
  content: "\e983";
}

.icon-sign-mxr:before {
  content: "\e984";
}

.icon-sign-myr-alt:before {
  content: "\e985";
}

.icon-sign-myr:before {
  content: "\e986";
}

.icon-sign-paypal-alt:before {
  content: "\e987";
}

.icon-sign-paypal-full:before {
  content: "\e988";
}

.icon-sign-php-alt:before {
  content: "\e989";
}

.icon-sign-php:before {
  content: "\e98a";
}

.icon-sign-pln-alt:before {
  content: "\e98b";
}

.icon-sign-pln:before {
  content: "\e98c";
}

.icon-sign-rub-alt:before {
  content: "\e98d";
}

.icon-sign-rub:before {
  content: "\e98e";
}

.icon-sign-sek-alt:before {
  content: "\e98f";
}

.icon-sign-sek:before {
  content: "\e990";
}

.icon-sign-sgd-alt:before {
  content: "\e991";
}

.icon-sign-sgd:before {
  content: "\e992";
}

.icon-sign-sign-kobo-alt:before {
  content: "\e993";
}

.icon-sign-sign-kobo:before {
  content: "\e994";
}

.icon-sign-steem-alt:before {
  content: "\e995";
}

.icon-sign-steem:before {
  content: "\e996";
}

.icon-sign-steller-alt:before {
  content: "\e997";
}

.icon-sign-steller:before {
  content: "\e998";
}

.icon-sign-stripe-fulll:before {
  content: "\e999";
}

.icon-sign-thb-alt:before {
  content: "\e99a";
}

.icon-sign-thb:before {
  content: "\e99b";
}

.icon-sign-trx-alt:before {
  content: "\e99c";
}

.icon-sign-trx:before {
  content: "\e99d";
}

.icon-sign-try-alt:before {
  content: "\e99e";
}

.icon-sign-try:before {
  content: "\e99f";
}

.icon-sign-usd-alt:before {
  content: "\e9a0";
}

.icon-sign-usd-alt2:before {
  content: "\e9a1";
}

.icon-sign-usd:before {
  content: "\e9a2";
}

.icon-sign-usdc-alt:before {
  content: "\e9a3";
}

.icon-sign-usdc:before {
  content: "\e9a4";
}

.icon-sign-usdt-alt:before {
  content: "\e9a5";
}

.icon-sign-usdt:before {
  content: "\e9a6";
}

.icon-sign-visa-alt:before {
  content: "\e9a7";
}

.icon-sign-vnd-alt:before {
  content: "\e9a8";
}

.icon-sign-vnd:before {
  content: "\e9a9";
}

.icon-sign-waves-alt:before {
  content: "\e9aa";
}

.icon-sign-waves:before {
  content: "\e9ab";
}

.icon-sign-xem-alt:before {
  content: "\e9ac";
}

.icon-sign-xem:before {
  content: "\e9ad";
}

.icon-sign-xrp-new-alt:before {
  content: "\e9ae";
}

.icon-sign-xrp-old-alt:before {
  content: "\e9af";
}

.icon-sign-xrp-old:before {
  content: "\e9b0";
}

.icon-sign-xrp:before {
  content: "\e9b1";
}

.icon-sign-zcash-alt:before {
  content: "\e9b2";
}

.icon-sign-zcash:before {
  content: "\e9b3";
}

.icon-signal:before {
  content: "\e9b4";
}

.icon-signin:before {
  content: "\e9b5";
}

.icon-signout:before {
  content: "\e9b6";
}

.icon-skype:before {
  content: "\e9b7";
}

.icon-slack-hash:before {
  content: "\e9b8";
}

.icon-slack:before {
  content: "\e9b9";
}

.icon-snapchat-fill:before {
  content: "\e9ba";
}

.icon-snapchat:before {
  content: "\e9bb";
}

.icon-sort-down-fill:before {
  content: "\e9bc";
}

.icon-sort-down:before {
  content: "\e9bd";
}

.icon-sort-fill:before {
  content: "\e9be";
}

.icon-sort-line:before {
  content: "\e9bf";
}

.icon-sort-up-fill:before {
  content: "\e9c0";
}

.icon-sort-up:before {
  content: "\e9c1";
}

.icon-sort-v:before {
  content: "\e9c2";
}

.icon-sort:before {
  content: "\e9c3";
}

.icon-soundcloud:before {
  content: "\e9c4";
}

.icon-spark-fill:before {
  content: "\e9c5";
}

.icon-spark-off-fill:before {
  content: "\e9c6";
}

.icon-spark-off:before {
  content: "\e9c7";
}

.icon-spark:before {
  content: "\e9c8";
}

.icon-speed:before {
  content: "\e9c9";
}

.icon-spotify:before {
  content: "\e9ca";
}

.icon-square-c:before {
  content: "\e9cb";
}

.icon-square-fill-c:before {
  content: "\e9cc";
}

.icon-square-fill:before {
  content: "\e9cd";
}

.icon-square:before {
  content: "\e9ce";
}

.icon-stack-overflow:before {
  content: "\e9cf";
}

.icon-star-fill:before {
  content: "\e9d0";
}

.icon-star-half-fill:before {
  content: "\e9d1";
}

.icon-star-half:before {
  content: "\e9d2";
}

.icon-star-round:before {
  content: "\e9d3";
}

.icon-star:before {
  content: "\e9d4";
}

.icon-step-back:before {
  content: "\e9d5";
}

.icon-step-forward:before {
  content: "\e9d6";
}

.icon-stop-circle-fill:before {
  content: "\e9d7";
}

.icon-stop-circle:before {
  content: "\e9d8";
}

.icon-stop-fill:before {
  content: "\e9d9";
}

.icon-stop:before {
  content: "\e9da";
}

.icon-stripe:before {
  content: "\e9db";
}

.icon-sun-fill:before {
  content: "\e9dc";
}

.icon-sun:before {
  content: "\e9dd";
}

.icon-swap-alt-fill:before {
  content: "\e9de";
}

.icon-swap-alt:before {
  content: "\e9df";
}

.icon-swap-v:before {
  content: "\e9e0";
}

.icon-swap:before {
  content: "\e9e1";
}

.icon-table-view-fill:before {
  content: "\e9e2";
}

.icon-table-view:before {
  content: "\e9e3";
}

.icon-tablet:before {
  content: "\e9e4";
}

.icon-tag-alt-fill:before {
  content: "\e9e5";
}

.icon-tag-alt:before {
  content: "\e9e6";
}

.icon-tag-fill:before {
  content: "\e9e7";
}

.icon-tag:before {
  content: "\e9e8";
}

.icon-tags-fill:before {
  content: "\e9e9";
}

.icon-tags:before {
  content: "\e9ea";
}

.icon-target:before {
  content: "\e9eb";
}

.icon-task-c:before {
  content: "\e9ec";
}

.icon-task-fill-c:before {
  content: "\e9ed";
}

.icon-task:before {
  content: "\e9ee";
}

.icon-telegram-circle:before {
  content: "\e9ef";
}

.icon-telegram:before {
  content: "\e9f0";
}

.icon-template-fill:before {
  content: "\e9f1";
}

.icon-template:before {
  content: "\e9f2";
}

.icon-terminal:before {
  content: "\e9f3";
}

.icon-text-a:before {
  content: "\e9f4";
}

.icon-text-rich:before {
  content: "\e9f5";
}

.icon-text:before {
  content: "\e9f6";
}

.icon-text2:before {
  content: "\e9f7";
}

.icon-thumbs-down:before {
  content: "\e9f8";
}

.icon-thumbs-up:before {
  content: "\e9f9";
}

.icon-ticket-alt-fill:before {
  content: "\e9fa";
}

.icon-ticket-alt:before {
  content: "\e9fb";
}

.icon-ticket-fill:before {
  content: "\e9fc";
}

.icon-ticket-minus-fill:before {
  content: "\e9fd";
}

.icon-ticket-minus:before {
  content: "\e9fe";
}

.icon-ticket-plus-fill:before {
  content: "\e9ff";
}

.icon-ticket-plus:before {
  content: "\ea00";
}

.icon-ticket:before {
  content: "\ea01";
}

.icon-tile-thumb-fill:before {
  content: "\ea02";
}

.icon-tile-thumb:before {
  content: "\ea03";
}

.icon-todo-fill:before {
  content: "\ea04";
}

.icon-todo:before {
  content: "\ea05";
}

.icon-toggle-off:before {
  content: "\ea06";
}

.icon-toggle-on:before {
  content: "\ea07";
}

.icon-toolbar-fill:before {
  content: "\ea08";
}

.icon-toolbar:before {
  content: "\ea09";
}

.icon-tranx-fill:before {
  content: "\ea0a";
}

.icon-tranx:before {
  content: "\ea0b";
}

.icon-trash-alt:before {
  content: "\ea0c";
}

.icon-trash-empty-fill:before {
  content: "\ea0d";
}

.icon-trash-empty:before {
  content: "\ea0e";
}

.icon-trash-fill:before {
  content: "\ea0f";
}

.icon-trash:before {
  content: "\ea10";
}

.icon-trend-down:before {
  content: "\ea11";
}

.icon-trend-up:before {
  content: "\ea12";
}

.icon-truck:before {
  content: "\ea13";
}

.icon-tumblr-round:before {
  content: "\ea14";
}

.icon-tumblr:before {
  content: "\ea15";
}

.icon-twitter-round:before {
  content: "\ea16";
}

.icon-twitter:before {
  content: "\ea17";
}

.icon-ubuntu:before {
  content: "\ea18";
}

.icon-umbrela:before {
  content: "\ea19";
}

.icon-unarchive-fill:before {
  content: "\ea1a";
}

.icon-unarchive:before {
  content: "\ea1b";
}

.icon-underline:before {
  content: "\ea1c";
}

.icon-undo:before {
  content: "\ea1d";
}

.icon-unfold-less:before {
  content: "\ea1e";
}

.icon-unfold-more:before {
  content: "\ea1f";
}

.icon-unlink-alt:before {
  content: "\ea20";
}

.icon-unlink:before {
  content: "\ea21";
}

.icon-unlock-fill:before {
  content: "\ea22";
}

.icon-unlock:before {
  content: "\ea23";
}

.icon-update:before {
  content: "\ea24";
}

.icon-upload-cloud:before {
  content: "\ea25";
}

.icon-upload:before {
  content: "\ea26";
}

.icon-upword-alt-fill:before {
  content: "\ea27";
}

.icon-upword-alt:before {
  content: "\ea28";
}

.icon-upword-ios:before {
  content: "\ea29";
}

.icon-user-add-fill:before {
  content: "\ea2a";
}

.icon-user-add:before {
  content: "\ea2b";
}

.icon-user-alt-fill:before {
  content: "\ea2c";
}

.icon-user-alt:before {
  content: "\ea2d";
}

.icon-user-c:before {
  content: "\ea2e";
}

.icon-user-check-fill:before {
  content: "\ea2f";
}

.icon-user-check:before {
  content: "\ea30";
}

.icon-user-cicle:before {
  content: "\ea31";
}

.icon-user-circle-fill:before {
  content: "\ea32";
}

.icon-user-cross-fill:before {
  content: "\ea33";
}

.icon-user-cross:before {
  content: "\ea34";
}

.icon-user-fill-c:before {
  content: "\ea35";
}

.icon-user-fill:before {
  content: "\ea36";
}

.icon-user-list-fill:before {
  content: "\ea37";
}

.icon-user-list:before {
  content: "\ea38";
}

.icon-user-remove-fill:before {
  content: "\ea39";
}

.icon-user-remove:before {
  content: "\ea3a";
}

.icon-user-round:before {
  content: "\ea3b";
}

.icon-user:before {
  content: "\ea3c";
}

.icon-users-fill:before {
  content: "\ea3d";
}

.icon-users:before {
  content: "\ea3e";
}

.icon-viber:before {
  content: "\ea3f";
}

.icon-video-fill:before {
  content: "\ea40";
}

.icon-video-off:before {
  content: "\ea41";
}

.icon-video:before {
  content: "\ea42";
}

.icon-view-col-fill:before {
  content: "\ea43";
}

.icon-view-col-sq:before {
  content: "\ea44";
}

.icon-view-col:before {
  content: "\ea45";
}

.icon-view-col2:before {
  content: "\ea46";
}

.icon-view-col3:before {
  content: "\ea47";
}

.icon-view-cols-fill:before {
  content: "\ea48";
}

.icon-view-cols-sq:before {
  content: "\ea49";
}

.icon-view-cols:before {
  content: "\ea4a";
}

.icon-view-grid-fill:before {
  content: "\ea4b";
}

.icon-view-grid-sq:before {
  content: "\ea4c";
}

.icon-view-grid-wd:before {
  content: "\ea4d";
}

.icon-view-grid:before {
  content: "\ea4e";
}

.icon-view-grid2-wd:before {
  content: "\ea4f";
}

.icon-view-grid3-wd:before {
  content: "\ea50";
}

.icon-view-group-fill:before {
  content: "\ea51";
}

.icon-view-group-wd:before {
  content: "\ea52";
}

.icon-view-list-fill:before {
  content: "\ea53";
}

.icon-view-list-sq:before {
  content: "\ea54";
}

.icon-view-list-wd:before {
  content: "\ea55";
}

.icon-view-list:before {
  content: "\ea56";
}

.icon-view-panel-fill:before {
  content: "\ea57";
}

.icon-view-panel-sq:before {
  content: "\ea58";
}

.icon-view-panel:before {
  content: "\ea59";
}

.icon-view-row-fill:before {
  content: "\ea5a";
}

.icon-view-row-sq:before {
  content: "\ea5b";
}

.icon-view-row-wd:before {
  content: "\ea5c";
}

.icon-view-row:before {
  content: "\ea5d";
}

.icon-view-x1:before {
  content: "\ea5e";
}

.icon-view-x2:before {
  content: "\ea5f";
}

.icon-view-x3:before {
  content: "\ea60";
}

.icon-view-x4:before {
  content: "\ea61";
}

.icon-view-x5:before {
  content: "\ea62";
}

.icon-view-x6:before {
  content: "\ea63";
}

.icon-view-x7:before {
  content: "\ea64";
}

.icon-vimeo-fill:before {
  content: "\ea65";
}

.icon-vimeo:before {
  content: "\ea66";
}

.icon-vine:before {
  content: "\ea67";
}

.icon-virus:before {
  content: "\ea68";
}

.icon-visa-alt:before {
  content: "\ea69";
}

.icon-visa:before {
  content: "\ea6a";
}

.icon-vol-half:before {
  content: "\ea6b";
}

.icon-vol-no:before {
  content: "\ea6c";
}

.icon-vol-off:before {
  content: "\ea6d";
}

.icon-vol:before {
  content: "\ea6e";
}

.icon-wallet-alt:before {
  content: "\ea6f";
}

.icon-wallet-fill:before {
  content: "\ea70";
}

.icon-wallet-in:before {
  content: "\ea71";
}

.icon-wallet-out:before {
  content: "\ea72";
}

.icon-wallet-saving:before {
  content: "\ea73";
}

.icon-wallet:before {
  content: "\ea74";
}

.icon-whatsapp-round:before {
  content: "\ea75";
}

.icon-whatsapp:before {
  content: "\ea76";
}

.icon-wifi-off:before {
  content: "\ea77";
}

.icon-wifi:before {
  content: "\ea78";
}

.icon-windows:before {
  content: "\ea79";
}

.icon-wordpress-fill:before {
  content: "\ea7a";
}

.icon-wordpress:before {
  content: "\ea7b";
}

.icon-yelp:before {
  content: "\ea7c";
}

.icon-yoast:before {
  content: "\ea7d";
}

.icon-youtube-fill:before {
  content: "\ea7e";
}

.icon-youtube-line:before {
  content: "\ea7f";
}

.icon-youtube-round:before {
  content: "\ea80";
}

.icon-zoom-in:before {
  content: "\ea81";
}

.icon-zoom-out:before {
  content: "\ea82";
}

.icon-account-setting-alt:before {
  content: "\ea83";
}

.icon-account-setting-fill:before {
  content: "\ea84";
}

.icon-account-setting:before {
  content: "\ea85";
}

.icon-activity-alt:before {
  content: "\ea86";
}

.icon-activity-round-fill:before {
  content: "\ea87";
}

.icon-activity-round:before {
  content: "\ea88";
}

.icon-activity:before {
  content: "\ea89";
}

.icon-adobe:before {
  content: "\ea8a";
}

.icon-airbnb:before {
  content: "\ea8b";
}

.icon-alarm-alt:before {
  content: "\ea8c";
}

.icon-alarm:before {
  content: "\ea8d";
}

.icon-alert-c:before {
  content: "\ea8e";
}

.icon-alert-circle-fill:before {
  content: "\ea8f";
}

.icon-alert-circle:before {
  content: "\ea90";
}

.icon-alert-fill-c:before {
  content: "\ea91";
}

.icon-alert-fill:before {
  content: "\ea92";
}

.icon-alert:before {
  content: "\ea93";
}

.icon-align-center:before {
  content: "\ea94";
}

.icon-align-justify:before {
  content: "\ea95";
}

.icon-align-left:before {
  content: "\ea96";
}

.icon-align-right:before {
  content: "\ea97";
}

.icon-amazon-pay-fill:before {
  content: "\ea98";
}

.icon-amazon-pay:before {
  content: "\ea99";
}

.icon-amazon:before {
  content: "\ea9a";
}

.icon-american-express:before {
  content: "\ea9b";
}

.icon-android:before {
  content: "\ea9c";
}

.icon-angular:before {
  content: "\ea9d";
}

.icon-aperture:before {
  content: "\ea9e";
}

.icon-apple-pay-fill:before {
  content: "\ea9f";
}

.icon-apple-pay:before {
  content: "\eaa0";
}

.icon-apple-store-ios:before {
  content: "\eaa1";
}

.icon-apple-store:before {
  content: "\eaa2";
}

.icon-apple:before {
  content: "\eaa3";
}

.icon-archive-fill:before {
  content: "\eaa4";
}

.icon-archive:before {
  content: "\eaa5";
}

.icon-archived-fill:before {
  content: "\eaa6";
}

.icon-archived:before {
  content: "\eaa7";
}

.icon-arrow-down-c:before {
  content: "\eaa8";
}

.icon-arrow-down-circle-fill:before {
  content: "\eaa9";
}

.icon-arrow-down-circle:before {
  content: "\eaaa";
}

.icon-arrow-down-fill-c:before {
  content: "\eaab";
}

.icon-arrow-down-left:before {
  content: "\eaac";
}

.icon-arrow-down-right:before {
  content: "\eaad";
}

.icon-arrow-down-round-fill:before {
  content: "\eaae";
}

.icon-arrow-down-round:before {
  content: "\eaaf";
}

.icon-arrow-down:before {
  content: "\eab0";
}

.icon-arrow-from-down:before {
  content: "\eab1";
}

.icon-arrow-from-left:before {
  content: "\eab2";
}

.icon-arrow-from-right:before {
  content: "\eab3";
}

.icon-arrow-from-up:before {
  content: "\eab4";
}

.icon-arrow-left-c:before {
  content: "\eab5";
}

.icon-arrow-left-circle-fill:before {
  content: "\eab6";
}

.icon-arrow-left-circle:before {
  content: "\eab7";
}

.icon-arrow-left-fill-c:before {
  content: "\eab8";
}

.icon-arrow-left-round-fill:before {
  content: "\eab9";
}

.icon-arrow-left-round:before {
  content: "\eaba";
}

.icon-arrow-left:before {
  content: "\eabb";
}

.icon-arrow-long-down:before {
  content: "\eabc";
}

.icon-arrow-long-left:before {
  content: "\eabd";
}

.icon-arrow-long-right:before {
  content: "\eabe";
}

.icon-arrow-long-up:before {
  content: "\eabf";
}

.icon-arrow-right-c:before {
  content: "\eac0";
}

.icon-arrow-right-circle-fill:before {
  content: "\eac1";
}

.icon-arrow-right-circle:before {
  content: "\eac2";
}

.icon-arrow-right-fill-c:before {
  content: "\eac3";
}

.icon-arrow-right-round-fill:before {
  content: "\eac4";
}

.icon-arrow-right-round:before {
  content: "\eac5";
}

.icon-arrow-right:before {
  content: "\eac6";
}

.icon-arrow-to-down:before {
  content: "\eac7";
}

.icon-arrow-to-left:before {
  content: "\eac8";
}

.icon-arrow-to-right:before {
  content: "\eac9";
}

.icon-arrow-to-up:before {
  content: "\eaca";
}

.icon-arrow-up-c:before {
  content: "\eacb";
}

.icon-arrow-up-circle-fill:before {
  content: "\eacc";
}

.icon-arrow-up-circle:before {
  content: "\eacd";
}

.icon-arrow-up-fill-c:before {
  content: "\eace";
}

.icon-arrow-up-left:before {
  content: "\eacf";
}

.icon-arrow-up-right:before {
  content: "\ead0";
}

.icon-arrow-up-round-fill:before {
  content: "\ead1";
}

.icon-arrow-up-round:before {
  content: "\ead2";
}

.icon-arrow-up:before {
  content: "\ead3";
}

.icon-article:before {
  content: "\ead4";
}

.icon-at:before {
  content: "\ead5";
}

.icon-award:before {
  content: "\ead6";
}

.icon-b-chrome:before {
  content: "\ead7";
}

.icon-b-edge:before {
  content: "\ead8";
}

.icon-b-firefox:before {
  content: "\ead9";
}

.icon-b-ie:before {
  content: "\eada";
}

.icon-b-opera:before {
  content: "\eadb";
}

.icon-b-safari:before {
  content: "\eadc";
}

.icon-b-si:before {
  content: "\eadd";
}

.icon-b-uc:before {
  content: "\eade";
}

.icon-back-alt-fill:before {
  content: "\eadf";
}

.icon-back-alt:before {
  content: "\eae0";
}

.icon-back-arrow-fill:before {
  content: "\eae1";
}

.icon-back-arrow:before {
  content: "\eae2";
}

.icon-back-ios:before {
  content: "\eae3";
}

.icon-bag-fill:before {
  content: "\eae4";
}

.icon-bag:before {
  content: "\eae5";
}

.icon-bar-c:before {
  content: "\eae6";
}

.icon-bar-chart-alt:before {
  content: "\eae7";
}

.icon-bar-chart-fill:before {
  content: "\eae8";
}

.icon-bar-chart:before {
  content: "\eae9";
}

.icon-bar-fill-c:before {
  content: "\eaea";
}

.icon-behance-fill:before {
  content: "\eaeb";
}

.icon-behance:before {
  content: "\eaec";
}

.icon-bell-fill:before {
  content: "\eaed";
}

.icon-bell-off-fill:before {
  content: "\eaee";
}

.icon-bell-off:before {
  content: "\eaef";
}

.icon-bell:before {
  content: "\eaf0";
}

.icon-blackberry:before {
  content: "\eaf1";
}

.icon-blank-alt:before {
  content: "\eaf2";
}

.icon-blank:before {
  content: "\eaf3";
}

.icon-blankf-fill:before {
  content: "\eaf4";
}

.icon-block-over:before {
  content: "\eaf5";
}

.icon-blogger-fill:before {
  content: "\eaf6";
}

.icon-blogger:before {
  content: "\eaf7";
}

.icon-bluetooth:before {
  content: "\eaf8";
}

.icon-bold:before {
  content: "\eaf9";
}

.icon-book-fill:before {
  content: "\eafa";
}

.icon-book-read:before {
  content: "\eafb";
}

.icon-book:before {
  content: "\eafc";
}

.icon-bookmark-fill:before {
  content: "\eafd";
}

.icon-bookmark:before {
  content: "\eafe";
}

.icon-bootstrap:before {
  content: "\eaff";
}

.icon-box-view-fill:before {
  content: "\eb00";
}

.icon-box-view:before {
  content: "\eb01";
}

.icon-box:before {
  content: "\eb02";
}

.icon-brick-fill:before {
  content: "\eb03";
}

.icon-brick:before {
  content: "\eb04";
}

.icon-briefcase:before {
  content: "\eb05";
}

.icon-browser-fill:before {
  content: "\eb06";
}

.icon-browser:before {
  content: "\eb07";
}

.icon-bugs:before {
  content: "\eb08";
}

.icon-building-fill:before {
  content: "\eb09";
}

.icon-building:before {
  content: "\eb0a";
}

.icon-bulb-fill:before {
  content: "\eb0b";
}

.icon-bulb:before {
  content: "\eb0c";
}

.icon-bullet-fill:before {
  content: "\eb0d";
}

.icon-bullet:before {
  content: "\eb0e";
}

.icon-calc:before {
  content: "\eb0f";
}

.icon-calendar-alt-fill:before {
  content: "\eb10";
}

.icon-calendar-alt:before {
  content: "\eb11";
}

.icon-calendar-booking-fill:before {
  content: "\eb12";
}

.icon-calendar-booking:before {
  content: "\eb13";
}

.icon-calendar-check-fill:before {
  content: "\eb14";
}

.icon-calendar-check:before {
  content: "\eb15";
}

.icon-calendar-fill:before {
  content: "\eb16";
}

.icon-calendar:before {
  content: "\eb17";
}

.icon-calender-date-fill:before {
  content: "\eb18";
}

.icon-calender-date:before {
  content: "\eb19";
}

.icon-call-alt-fill:before {
  content: "\eb1a";
}

.icon-call-alt:before {
  content: "\eb1b";
}

.icon-call-fill:before {
  content: "\eb1c";
}

.icon-call:before {
  content: "\eb1d";
}

.icon-camera-fill:before {
  content: "\eb1e";
}

.icon-camera:before {
  content: "\eb1f";
}

.icon-capsule-fill:before {
  content: "\eb20";
}

.icon-capsule:before {
  content: "\eb21";
}

.icon-card-view:before {
  content: "\eb22";
}

.icon-cards-fill:before {
  content: "\eb23";
}

.icon-cards:before {
  content: "\eb24";
}

.icon-caret-down-fill:before {
  content: "\eb25";
}

.icon-caret-down:before {
  content: "\eb26";
}

.icon-caret-left-fill:before {
  content: "\eb27";
}

.icon-caret-left:before {
  content: "\eb28";
}

.icon-caret-right-fill:before {
  content: "\eb29";
}

.icon-caret-right:before {
  content: "\eb2a";
}

.icon-caret-up-fill:before {
  content: "\eb2b";
}

.icon-caret-up:before {
  content: "\eb2c";
}

.icon-cart-fill:before {
  content: "\eb2d";
}

.icon-cart:before {
  content: "\eb2e";
}

.icon-caution-fill:before {
  content: "\eb2f";
}

.icon-caution:before {
  content: "\eb30";
}

.icon-cc-alt-fill:before {
  content: "\eb31";
}

.icon-cc-alt:before {
  content: "\eb32";
}

.icon-cc-alt2-fill:before {
  content: "\eb33";
}

.icon-cc-alt2:before {
  content: "\eb34";
}

.icon-cc-fill:before {
  content: "\eb35";
}

.icon-cc-new:before {
  content: "\eb36";
}

.icon-cc-off:before {
  content: "\eb37";
}

.icon-cc-paypal:before {
  content: "\eb38";
}

.icon-cc-secure-fill:before {
  content: "\eb39";
}

.icon-cc-secure:before {
  content: "\eb3a";
}

.icon-cc-stripe:before {
  content: "\eb3b";
}

.icon-cc:before {
  content: "\eb3c";
}

.icon-centos:before {
  content: "\eb3d";
}

.icon-chart-down:before {
  content: "\eb3e";
}

.icon-chart-up:before {
  content: "\eb3f";
}

.icon-chat-circle-fill:before {
  content: "\eb40";
}

.icon-chat-circle:before {
  content: "\eb41";
}

.icon-chat-fill:before {
  content: "\eb42";
}

.icon-chat:before {
  content: "\eb43";
}

.icon-check-c:before {
  content: "\eb44";
}

.icon-check-circle-cut:before {
  content: "\eb45";
}

.icon-check-circle-fill:before {
  content: "\eb46";
}

.icon-check-circle:before {
  content: "\eb47";
}

.icon-check-fill-c:before {
  content: "\eb48";
}

.icon-check-round-cut:before {
  content: "\eb49";
}

.icon-check-round-fill:before {
  content: "\eb4a";
}

.icon-check-round:before {
  content: "\eb4b";
}

.icon-check-thick:before {
  content: "\eb4c";
}

.icon-check:before {
  content: "\eb4d";
}

.icon-chevron-down-c:before {
  content: "\eb4e";
}

.icon-chevron-down-circle-fill:before {
  content: "\eb4f";
}

.icon-chevron-down-circle:before {
  content: "\eb50";
}

.icon-chevron-down-fill-c:before {
  content: "\eb51";
}

.icon-chevron-down-round-fill:before {
  content: "\eb52";
}

.icon-chevron-down-round:before {
  content: "\eb53";
}

.icon-chevron-down:before {
  content: "\eb54";
}

.icon-chevron-left-c:before {
  content: "\eb55";
}

.icon-chevron-left-circle-fill:before {
  content: "\eb56";
}

.icon-chevron-left-circle:before {
  content: "\eb57";
}

.icon-chevron-left-fill-c:before {
  content: "\eb58";
}

.icon-chevron-left-round-fill:before {
  content: "\eb59";
}

.icon-chevron-left-round:before {
  content: "\eb5a";
}

.icon-chevron-left:before {
  content: "\eb5b";
}

.icon-chevron-right-c:before {
  content: "\eb5c";
}

.icon-chevron-right-circle-fill:before {
  content: "\eb5d";
}

.icon-chevron-right-circle:before {
  content: "\eb5e";
}

.icon-chevron-right-fill-c:before {
  content: "\eb5f";
}

.icon-chevron-right-round-fill:before {
  content: "\eb60";
}

.icon-chevron-right-round:before {
  content: "\eb61";
}

.icon-chevron-right:before {
  content: "\eb62";
}

.icon-chevron-up-c:before {
  content: "\eb63";
}

.icon-chevron-up-circle-fill:before {
  content: "\eb64";
}

.icon-chevron-up-circle:before {
  content: "\eb65";
}

.icon-chevron-up-fill-c:before {
  content: "\eb66";
}

.icon-chevron-up-round-fill:before {
  content: "\eb67";
}

.icon-chevron-up-round:before {
  content: "\eb68";
}

.icon-chevron-up:before {
  content: "\eb69";
}

.icon-chevrons-down:before {
  content: "\eb6a";
}

.icon-chevrons-left:before {
  content: "\eb6b";
}

.icon-chevrons-right:before {
  content: "\eb6c";
}

.icon-chevrons-up:before {
  content: "\eb6d";
}

.icon-circle-fill:before {
  content: "\eb6e";
}

.icon-circle:before {
  content: "\eb6f";
}

.icon-clip-h:before {
  content: "\eb70";
}

.icon-clip-v:before {
  content: "\eb71";
}

.icon-clip:before {
  content: "\eb72";
}

.icon-clipboad-check-fill:before {
  content: "\eb73";
}

.icon-clipboad-check:before {
  content: "\eb74";
}

.icon-clipboard-fill:before {
  content: "\eb75";
}

.icon-clipboard:before {
  content: "\eb76";
}

.icon-clock-fill:before {
  content: "\eb77";
}

.icon-clock:before {
  content: "\eb78";
}

.icon-cloud-fill:before {
  content: "\eb79";
}

.icon-cloud:before {
  content: "\eb7a";
}

.icon-cmd:before {
  content: "\eb7b";
}

.icon-code-download:before {
  content: "\eb7c";
}

.icon-code:before {
  content: "\eb7d";
}

.icon-coffee-fill:before {
  content: "\eb7e";
}

.icon-coffee:before {
  content: "\eb7f";
}

.icon-coin-alt-fill:before {
  content: "\eb80";
}

.icon-coin-alt:before {
  content: "\eb81";
}

.icon-coin-eur:before {
  content: "\eb82";
}

.icon-coin-gbp:before {
  content: "\eb83";
}

.icon-coin:before {
  content: "\eb84";
}

.icon-coins:before {
  content: "\eb85";
}

.icon-col-2s:before {
  content: "\eb86";
}

.icon-col-3s:before {
  content: "\eb87";
}

.icon-col-4s:before {
  content: "\eb88";
}

.icon-col-view:before {
  content: "\eb89";
}

.icon-color-palette-fill:before {
  content: "\eb8a";
}

.icon-color-palette:before {
  content: "\eb8b";
}

.icon-comments:before {
  content: "\eb8c";
}

.icon-contact-fill:before {
  content: "\eb8d";
}

.icon-contact:before {
  content: "\eb8e";
}

.icon-copy-fill:before {
  content: "\eb8f";
}

.icon-copy:before {
  content: "\eb90";
}

.icon-covid:before {
  content: "\eb91";
}

.icon-cpu:before {
  content: "\eb92";
}

.icon-crop-alt:before {
  content: "\eb93";
}

.icon-crop:before {
  content: "\eb94";
}

.icon-cross-c:before {
  content: "\eb95";
}

.icon-cross-circle-fill:before {
  content: "\eb96";
}

.icon-cross-circle:before {
  content: "\eb97";
}

.icon-cross-fill-c:before {
  content: "\eb98";
}

.icon-cross-round-fill:before {
  content: "\eb99";
}

.icon-cross-round:before {
  content: "\eb9a";
}

.icon-cross-sm:before {
  content: "\eb9b";
}

.icon-cross:before {
  content: "\eb9c";
}

.icon-crosshair-fill:before {
  content: "\eb9d";
}

.icon-crosshair:before {
  content: "\eb9e";
}

.icon-css3-fill:before {
  content: "\eb9f";
}

.icon-css3:before {
  content: "\eba0";
}

.icon-curve-down-left:before {
  content: "\eba1";
}

.icon-curve-down-right:before {
  content: "\eba2";
}

.icon-curve-left-down:before {
  content: "\eba3";
}

.icon-curve-left-up:before {
  content: "\eba4";
}

.icon-curve-right-down:before {
  content: "\eba5";
}

.icon-curve-right-up:before {
  content: "\eba6";
}

.icon-curve-up-left:before {
  content: "\eba7";
}

.icon-curve-up-right:before {
  content: "\eba8";
}

.icon-dashboard-fill:before {
  content: "\eba9";
}

.icon-dashboard:before {
  content: "\ebaa";
}

.icon-dashlite-alt:before {
  content: "\ebab";
}

.icon-dashlite-circle:before {
  content: "\ebac";
}

.icon-dashlite:before {
  content: "\ebad";
}

.icon-db-fill:before {
  content: "\ebae";
}

.icon-db:before {
  content: "\ebaf";
}

.icon-delete-fill:before {
  content: "\ebb0";
}

.icon-delete:before {
  content: "\ebb1";
}

.icon-digital-ocean:before {
  content: "\ebb2";
}

.icon-discover:before {
  content: "\ebb3";
}

.icon-disk:before {
  content: "\ebb4";
}

.icon-done:before {
  content: "\ebb5";
}

.icon-dot-box-fill:before {
  content: "\ebb6";
}

.icon-dot-box:before {
  content: "\ebb7";
}

.icon-dot-sq:before {
  content: "\ebb8";
}

.icon-dot:before {
  content: "\ebb9";
}

.icon-download-cloud:before {
  content: "\ebba";
}

.icon-download:before {
  content: "\ebbb";
}

.icon-downward-alt-fill:before {
  content: "\ebbc";
}

.icon-downward-alt:before {
  content: "\ebbd";
}

.icon-downward-ios:before {
  content: "\ebbe";
}

.icon-dribbble-round:before {
  content: "\ebbf";
}

.icon-dribbble:before {
  content: "\ebc0";
}

.icon-dropbox:before {
  content: "\ebc1";
}

.icon-ebay:before {
  content: "\ebc2";
}

.icon-edit-alt-fill:before {
  content: "\ebc3";
}

.icon-edit-alt:before {
  content: "\ebc4";
}

.icon-edit-fill:before {
  content: "\ebc5";
}

.icon-edit:before {
  content: "\ebc6";
}

.icon-elementor:before {
  content: "\ebc7";
}

.icon-emails-fill:before {
  content: "\ebc8";
}

.icon-emails:before {
  content: "\ebc9";
}

.icon-envato:before {
  content: "\ebca";
}

.icon-equal-sm:before {
  content: "\ebcb";
}

.icon-equal:before {
  content: "\ebcc";
}

.icon-exchange-v:before {
  content: "\ebcd";
}

.icon-exchange:before {
  content: "\ebce";
}

.icon-expand:before {
  content: "\ebcf";
}

.icon-external-alt:before {
  content: "\ebd0";
}

.icon-external:before {
  content: "\ebd1";
}

.icon-eye-alt-fill:before {
  content: "\ebd2";
}

.icon-eye-alt:before {
  content: "\ebd3";
}

.icon-eye-fill:before {
  content: "\ebd4";
}

.icon-eye-off-fill:before {
  content: "\ebd5";
}

.icon-eye-off:before {
  content: "\ebd6";
}

.icon-eye:before {
  content: "\ebd7";
}

.icon-facebook-circle:before {
  content: "\ebd8";
}

.icon-facebook-f:before {
  content: "\ebd9";
}

.icon-facebook-fill:before {
  content: "\ebda";
}

.icon-fedora:before {
  content: "\ebdb";
}

.icon-file-check-fill:before {
  content: "\ebdc";
}

.icon-file-check:before {
  content: "\ebdd";
}

.icon-file-code:before {
  content: "\ebde";
}

.icon-file-doc:before {
  content: "\ebdf";
}

.icon-file-docs:before {
  content: "\ebe0";
}

.icon-file-download:before {
  content: "\ebe1";
}

.icon-file-fill:before {
  content: "\ebe2";
}

.icon-file-img:before {
  content: "\ebe3";
}

.icon-file-minus-fill:before {
  content: "\ebe4";
}

.icon-file-minus:before {
  content: "\ebe5";
}

.icon-file-pdf:before {
  content: "\ebe6";
}

.icon-file-plus-fill:before {
  content: "\ebe7";
}

.icon-file-plus:before {
  content: "\ebe8";
}

.icon-file-remove-fill:before {
  content: "\ebe9";
}

.icon-file-remove:before {
  content: "\ebea";
}

.icon-file-text-fill:before {
  content: "\ebeb";
}

.icon-file-text:before {
  content: "\ebec";
}

.icon-file-xls:before {
  content: "\ebed";
}

.icon-file-zip:before {
  content: "\ebee";
}

.icon-file:before {
  content: "\ebef";
}

.icon-files-fill:before {
  content: "\ebf0";
}

.icon-files:before {
  content: "\ebf1";
}

.icon-filter-alt:before {
  content: "\ebf2";
}

.icon-filter-fill:before {
  content: "\ebf3";
}

.icon-filter:before {
  content: "\ebf4";
}

.icon-first:before {
  content: "\ebf5";
}

.icon-flag-fill:before {
  content: "\ebf6";
}

.icon-flag:before {
  content: "\ebf7";
}

.icon-flickr-round:before {
  content: "\ebf8";
}

.icon-flickr:before {
  content: "\ebf9";
}

.icon-focus:before {
  content: "\ebfa";
}

.icon-folder-check:before {
  content: "\ebfb";
}

.icon-folder-fill:before {
  content: "\ebfc";
}

.icon-folder-list:before {
  content: "\ebfd";
}

.icon-folder-minus:before {
  content: "\ebfe";
}

.icon-folder-plus:before {
  content: "\ebff";
}

.icon-folder-remove:before {
  content: "\ec00";
}

.icon-folder:before {
  content: "\ec01";
}

.icon-folders-fill:before {
  content: "\ec02";
}

.icon-folders:before {
  content: "\ec03";
}

.icon-footer:before {
  content: "\ec04";
}

.icon-forward-alt-fill:before {
  content: "\ec05";
}

.icon-forward-alt:before {
  content: "\ec06";
}

.icon-forward-arrow-fill:before {
  content: "\ec07";
}

.icon-forward-arrow:before {
  content: "\ec08";
}

.icon-forward-fill:before {
  content: "\ec09";
}

.icon-forward-ios:before {
  content: "\ec0a";
}

.icon-forward:before {
  content: "\ec0b";
}

.icon-gift:before {
  content: "\ec0c";
}

.icon-git:before {
  content: "\ec0d";
}

.icon-github-circle:before {
  content: "\ec0e";
}

.icon-github-round:before {
  content: "\ec0f";
}

.icon-github:before {
  content: "\ec10";
}

.icon-globe:before {
  content: "\ec11";
}

.icon-google-drive:before {
  content: "\ec12";
}

.icon-google-pay-fill:before {
  content: "\ec13";
}

.icon-google-pay:before {
  content: "\ec14";
}

.icon-google-play-store:before {
  content: "\ec15";
}

.icon-google-wallet:before {
  content: "\ec16";
}

.icon-google:before {
  content: "\ec17";
}

.icon-grid-add-c:before {
  content: "\ec18";
}

.icon-grid-add-fill-c:before {
  content: "\ec19";
}

.icon-grid-alt-fill:before {
  content: "\ec1a";
}

.icon-grid-alt:before {
  content: "\ec1b";
}

.icon-grid-box-alt-fill:before {
  content: "\ec1c";
}

.icon-grid-box-alt:before {
  content: "\ec1d";
}

.icon-grid-box-fill:before {
  content: "\ec1e";
}

.icon-grid-box:before {
  content: "\ec1f";
}

.icon-grid-c:before {
  content: "\ec20";
}

.icon-grid-fill-c:before {
  content: "\ec21";
}

.icon-grid-fill:before {
  content: "\ec22";
}

.icon-grid-line:before {
  content: "\ec23";
}

.icon-grid-plus-fill:before {
  content: "\ec24";
}

.icon-grid-plus:before {
  content: "\ec25";
}

.icon-grid-sq:before {
  content: "\ec26";
}

.icon-grid:before {
  content: "\ec27";
}

.icon-growth-fill:before {
  content: "\ec28";
}

.icon-growth:before {
  content: "\ec29";
}

.icon-hangout:before {
  content: "\ec2a";
}

.icon-happy:before {
  content: "\ec2b";
}

.icon-happyf-fill:before {
  content: "\ec2c";
}

.icon-hard-drive:before {
  content: "\ec2d";
}

.icon-hash:before {
  content: "\ec2e";
}

.icon-header:before {
  content: "\ec2f";
}

.icon-heading:before {
  content: "\ec30";
}

.icon-headphone-fill:before {
  content: "\ec31";
}

.icon-headphone:before {
  content: "\ec32";
}

.icon-heart-fill:before {
  content: "\ec33";
}

.icon-heart:before {
  content: "\ec34";
}

.icon-help-alt:before {
  content: "\ec35";
}

.icon-help-fill:before {
  content: "\ec36";
}

.icon-help:before {
  content: "\ec37";
}

.icon-histroy:before {
  content: "\ec38";
}

.icon-home-alt:before {
  content: "\ec39";
}

.icon-home-fill:before {
  content: "\ec3a";
}

.icon-home:before {
  content: "\ec3b";
}

.icon-hot-fill:before {
  content: "\ec3c";
}

.icon-hot:before {
  content: "\ec3d";
}

.icon-houzz:before {
  content: "\ec3e";
}

.icon-html5:before {
  content: "\ec3f";
}

.icon-img-fill:before {
  content: "\ec40";
}

.icon-img:before {
  content: "\ec41";
}

.icon-in-person:before {
  content: "\ec42";
}

.icon-inbox-fill:before {
  content: "\ec43";
}

.icon-inbox-in-fill:before {
  content: "\ec44";
}

.icon-inbox-in:before {
  content: "\ec45";
}

.icon-inbox-out-fill:before {
  content: "\ec46";
}

.icon-inbox-out:before {
  content: "\ec47";
}

.icon-inbox:before {
  content: "\ec48";
}

.icon-info-fill:before {
  content: "\ec49";
}

.icon-info-i:before {
  content: "\ec4a";
}

.icon-info:before {
  content: "\ec4b";
}

.icon-instagram-round:before {
  content: "\ec4c";
}

.icon-instagram:before {
  content: "\ec4d";
}

.icon-invest:before {
  content: "\ec4e";
}

.icon-italic:before {
  content: "\ec4f";
}

.icon-jcb:before {
  content: "\ec50";
}

.icon-joomla:before {
  content: "\ec51";
}

.icon-js:before {
  content: "\ec52";
}

.icon-kickstarter:before {
  content: "\ec53";
}

.icon-label-alt-fill:before {
  content: "\ec54";
}

.icon-label-alt:before {
  content: "\ec55";
}

.icon-label-fill:before {
  content: "\ec56";
}

.icon-label:before {
  content: "\ec57";
}

.icon-laptop:before {
  content: "\ec58";
}

.icon-laravel:before {
  content: "\ec59";
}

.icon-last:before {
  content: "\ec5a";
}

.icon-layer-fill:before {
  content: "\ec5b";
}

.icon-layer:before {
  content: "\ec5c";
}

.icon-layers-fill:before {
  content: "\ec5d";
}

.icon-layers:before {
  content: "\ec5e";
}

.icon-layout-alt-fill:before {
  content: "\ec5f";
}

.icon-layout-alt:before {
  content: "\ec60";
}

.icon-layout-fill:before {
  content: "\ec61";
}

.icon-layout-fill1:before {
  content: "\ec62";
}

.icon-layout:before {
  content: "\ec63";
}

.icon-layout1:before {
  content: "\ec64";
}

.icon-layout2:before {
  content: "\ec65";
}

.icon-light-fill:before {
  content: "\ec66";
}

.icon-light:before {
  content: "\ec67";
}

.icon-line-chart-down:before {
  content: "\ec68";
}

.icon-line-chart-up:before {
  content: "\ec69";
}

.icon-line-chart:before {
  content: "\ec6a";
}

.icon-link-alt:before {
  content: "\ec6b";
}

.icon-link-group:before {
  content: "\ec6c";
}

.icon-link-h:before {
  content: "\ec6d";
}

.icon-link-v:before {
  content: "\ec6e";
}

.icon-link:before {
  content: "\ec6f";
}

.icon-linkedin-round:before {
  content: "\ec70";
}

.icon-linkedin:before {
  content: "\ec71";
}

.icon-linux-server:before {
  content: "\ec72";
}

.icon-linux:before {
  content: "\ec73";
}

.icon-list-check:before {
  content: "\ec74";
}

.icon-list-fill:before {
  content: "\ec75";
}

.icon-list-index-fill:before {
  content: "\ec76";
}

.icon-list-index:before {
  content: "\ec77";
}

.icon-list-ol:before {
  content: "\ec78";
}

.icon-list-round:before {
  content: "\ec79";
}

.icon-list-thumb-alt-fill:before {
  content: "\ec7a";
}

.icon-list-thumb-alt:before {
  content: "\ec7b";
}

.icon-list-thumb-fill:before {
  content: "\ec7c";
}

.icon-list-thumb:before {
  content: "\ec7d";
}

.icon-list:before {
  content: "\ec7e";
}

.icon-live:before {
  content: "\ec7f";
}

.icon-loader:before {
  content: "\ec80";
}

.icon-location:before {
  content: "\ec81";
}

.icon-lock-alt-fill:before {
  content: "\ec82";
}

.icon-lock-alt:before {
  content: "\ec83";
}

.icon-lock-fill:before {
  content: "\ec84";
}

.icon-lock:before {
  content: "\ec85";
}

.icon-mail-fill:before {
  content: "\ec86";
}

.icon-mail:before {
  content: "\ec87";
}

.icon-mailchimp:before {
  content: "\ec88";
}

.icon-map-pin-fill:before {
  content: "\ec89";
}

.icon-map-pin:before {
  content: "\ec8a";
}

.icon-map:before {
  content: "\ec8b";
}

.icon-marter-card:before {
  content: "\ec8c";
}

.icon-masonry-fill:before {
  content: "\ec8d";
}

.icon-masonry:before {
  content: "\ec8e";
}

.icon-maximize-alt:before {
  content: "\ec8f";
}

.icon-maximize:before {
  content: "\ec90";
}

.icon-mc:before {
  content: "\ec91";
}

.icon-medium-round:before {
  content: "\ec92";
}

.icon-medium:before {
  content: "\ec93";
}

.icon-megento:before {
  content: "\ec94";
}

.icon-meh-fill:before {
  content: "\ec95";
}

.icon-meh:before {
  content: "\ec96";
}

.icon-menu-alt-left:before {
  content: "\ec97";
}

.icon-menu-alt-r:before {
  content: "\ec98";
}

.icon-menu-alt:before {
  content: "\ec99";
}

.icon-menu-center:before {
  content: "\ec9a";
}

.icon-menu-circled:before {
  content: "\ec9b";
}

.icon-menu-left:before {
  content: "\ec9c";
}

.icon-menu-right:before {
  content: "\ec9d";
}

.icon-menu-squared:before {
  content: "\ec9e";
}

.icon-menu:before {
  content: "\ec9f";
}

.icon-meter:before {
  content: "\eca0";
}

.icon-mic-off:before {
  content: "\eca1";
}

.icon-mic:before {
  content: "\eca2";
}

.icon-microsoft:before {
  content: "\eca3";
}

.icon-minimize-alt:before {
  content: "\eca4";
}

.icon-minimize:before {
  content: "\eca5";
}

.icon-minus-c:before {
  content: "\eca6";
}

.icon-minus-circle-fill:before {
  content: "\eca7";
}

.icon-minus-circle:before {
  content: "\eca8";
}

.icon-minus-fill-c:before {
  content: "\eca9";
}

.icon-minus-round-fill:before {
  content: "\ecaa";
}

.icon-minus-round:before {
  content: "\ecab";
}

.icon-minus-sm:before {
  content: "\ecac";
}

.icon-minus:before {
  content: "\ecad";
}

.icon-mobile:before {
  content: "\ecae";
}

.icon-money:before {
  content: "\ecaf";
}

.icon-monitor:before {
  content: "\ecb0";
}

.icon-moon-fill:before {
  content: "\ecb1";
}

.icon-moon:before {
  content: "\ecb2";
}

.icon-more-h-alt:before {
  content: "\ecb3";
}

.icon-more-h:before {
  content: "\ecb4";
}

.icon-more-v-alt:before {
  content: "\ecb5";
}

.icon-more-v:before {
  content: "\ecb6";
}

.icon-move:before {
  content: "\ecb7";
}

.icon-movie:before {
  content: "\ecb8";
}

.icon-msg-circle-fill:before {
  content: "\ecb9";
}

.icon-msg-circle:before {
  content: "\ecba";
}

.icon-msg-fill:before {
  content: "\ecbb";
}

.icon-msg:before {
  content: "\ecbc";
}

.icon-music:before {
  content: "\ecbd";
}

.icon-na:before {
  content: "\ecbe";
}

.icon-navigate-fill:before {
  content: "\ecbf";
}

.icon-navigate-up-fill:before {
  content: "\ecc0";
}

.icon-navigate-up:before {
  content: "\ecc1";
}

.icon-navigate:before {
  content: "\ecc2";
}

.icon-network:before {
  content: "\ecc3";
}

.icon-note-add-c:before {
  content: "\ecc4";
}

.icon-note-add-fill-c:before {
  content: "\ecc5";
}

.icon-note-add-fill:before {
  content: "\ecc6";
}

.icon-note-add:before {
  content: "\ecc7";
}

.icon-notes-alt:before {
  content: "\ecc8";
}

.icon-notes:before {
  content: "\ecc9";
}

.icon-notice:before {
  content: "\ecca";
}

.icon-notify:before {
  content: "\eccb";
}

.icon-offer-fill:before {
  content: "\eccc";
}

.icon-offer:before {
  content: "\eccd";
}

.icon-opt-alt-fill:before {
  content: "\ecce";
}

.icon-opt-alt:before {
  content: "\eccf";
}

.icon-opt-dot-alt:before {
  content: "\ecd0";
}

.icon-opt-dot-fill:before {
  content: "\ecd1";
}

.icon-opt-dot:before {
  content: "\ecd2";
}

.icon-opt:before {
  content: "\ecd3";
}

.icon-package-fill:before {
  content: "\ecd4";
}

.icon-package:before {
  content: "\ecd5";
}

.icon-panel-fill:before {
  content: "\ecd6";
}

.icon-panel:before {
  content: "\ecd7";
}

.icon-pause-circle-fill:before {
  content: "\ecd8";
}

.icon-pause-circle:before {
  content: "\ecd9";
}

.icon-pause-fill:before {
  content: "\ecda";
}

.icon-pause:before {
  content: "\ecdb";
}

.icon-paypal-alt:before {
  content: "\ecdc";
}

.icon-paypal:before {
  content: "\ecdd";
}

.icon-pen-alt-fill:before {
  content: "\ecde";
}

.icon-pen-fill:before {
  content: "\ecdf";
}

.icon-pen:before {
  content: "\ece0";
}

.icon-pen2:before {
  content: "\ece1";
}

.icon-percent:before {
  content: "\ece2";
}

.icon-php:before {
  content: "\ece3";
}

.icon-pie-alt:before {
  content: "\ece4";
}

.icon-pie-fill:before {
  content: "\ece5";
}

.icon-pie:before {
  content: "\ece6";
}

.icon-pinterest-circle:before {
  content: "\ece7";
}

.icon-pinterest-round:before {
  content: "\ece8";
}

.icon-pinterest:before {
  content: "\ece9";
}

.icon-piority-fill:before {
  content: "\ecea";
}

.icon-piority:before {
  content: "\eceb";
}

.icon-play-circle-fill:before {
  content: "\ecec";
}

.icon-play-circle:before {
  content: "\eced";
}

.icon-play-fill:before {
  content: "\ecee";
}

.icon-play:before {
  content: "\ecef";
}

.icon-plus-c:before {
  content: "\ecf0";
}

.icon-plus-circle:before {
  content: "\ecf1";
}

.icon-plus:before {
  content: "\ecf2";
}
