/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2020 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --cafe-base: #b48254;
  --cafe-dark: #6c4e32;
  --cafe-light: #c39b76;
  --cafe-lighter: #f0e6dd;
  --cafe-lightest: #fcf9f7;
  --colors-black: #000000;
  --colors-white: #ffffff;
  --danger-base: #d13417;
  --danger-dark: #7d1f0e;
  --danger-light: #db5d46;
  --danger-lighter: #f6d7d1;
  --danger-lightest: #fdf5f4;
  --info-base: #338fbd;
  --info-dark: #1e5571;
  --info-light: #5ba5ca;
  --info-lighter: #d6e8f1;
  --info-lightest: #f4f9fb;
  --neutrals-100: #ededee;
  --neutrals-300: #b9b9bd;
  --neutrals-600: #404047;
  --neutrals-700: #303036;
  --neutrals-900: #101011;
  --neutrals-base: #505059;
  --neutrals-dark: #202023;
  --neutrals-light: #73737b;
  --neutrals-lighter: #dcdcde;
  --neutrals-lightest: #f6f6f6;
  --sea-100: #e8f1f2;
  --sea-300: #a3c8cb;
  --sea-600: #155f65;
  --sea-700: #10474c;
  --sea-900: #051719;
  --sea-base: #1b777f;
  --sea-dark: #10474c;
  --sea-light: #489298;
  --sea-lighter: #d1e4e5;
  --sea-lightest: #f3f8f8;
  --success-base: #228f65;
  --success-dark: #14553c;
  --success-light: #4fa684;
  --success-lighter: #d2e8e0;
  --success-lightest: #f4f9f7;
  --warning-base: #f7a63e;
  --warning-dark: #946325;
  --warning-light: #f8b864;
  --warning-lighter: #fdedd8;
  --warning-lightest: #fffbf6;
}
